@use 'sass:list';
@import '../../shared/vars';

$psv-settings-margin: 10px !default;
$psv-settings-font: $psv-caption-font !default;
$psv-settings-item-height: $psv-panel-menu-item-height !default;
$psv-settings-item-padding: $psv-panel-menu-item-padding !default;
$psv-settings-background: $psv-panel-background !default;
$psv-settings-shadow: 0 0 5px $psv-settings-background !default;
$psv-settings-text-color: $psv-panel-text-color !default;
$psv-settings-hover-background: $psv-panel-menu-hover-background !default;
$psv-settings-badge-font: 10px / 0.9 monospace !default;
$psv-settings-badge-background: #111 !default;
$psv-settings-badge-text-color: white !default;

.psv-container {
    --psv-settings-plugin-loaded: true;
}

.psv-settings {
    position: absolute;
    bottom: $psv-navbar-height;
    background: $psv-settings-background;
    box-shadow: $psv-settings-shadow;
    font: $psv-settings-font;
    color: $psv-settings-text-color;
    z-index: $psv-navbar-zindex;
    opacity: 0;
    transition: opacity 0.1s linear;
    margin: $psv-settings-margin;

    &--open {
        opacity: 1;
    }

    &-list {
        list-style: none;
        margin: 0;
        padding: 0;
    }
}

.psv-settings-item {
    height: $psv-settings-item-height;
    padding: $psv-settings-item-padding;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;

    &:hover {
        background: $psv-settings-hover-background;
    }

    &:focus-visible {
        outline: $psv-element-focus-outline;
        outline-offset: -#{list.nth($psv-element-focus-outline, 1)};
    }

    *:not(:last-child) {
        margin-right: 1em;
    }

    &-label {
        flex: 1;
        font-weight: bold;
    }

    &-value {
        flex: none;
    }

    &-icon {
        flex: none;
        height: 1em;
        width: 1em;

        svg {
            width: 100%;
            height: 100%;
            vertical-align: initial;
        }
    }

    &--header {
        border-bottom: 1px solid currentcolor;

        svg {
            transform: scaleX(-1);
        }
    }
}

.psv-settings-badge {
    position: absolute;
    top: 10%;
    right: 10%;
    border-radius: 0.2em;
    padding: 0.2em;
    background: $psv-settings-badge-background;
    color: $psv-settings-badge-text-color;
    font: $psv-settings-badge-font;
}
